
import { Application, Context, DiagramTool, DisplayObject, Ticker } from '@icepanel/app-canvas'
import { IPoint, pointInRect, rectIntersects, snapToGrid } from '@icepanel/app-graphics'
import { Comment, CommentBodyType, Diagram, DiagramComment, DiagramCommentPartial, DiagramConnection, DiagramConnectionPartial, DiagramContent, DiagramContentPartial, DiagramObject, DiagramObjectPartial, Domain, Flow, FlowStep, ModelConnection, ModelConnectionTechnology, ModelObject, ModelObjectTechnology, ModelObjectType, ModelStatus, modelStatuses, PermissionType, Tag, TagColor, TagGroup, TagGroupIcon, Task, Team } from '@icepanel/platform-api-client'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import throttle from 'lodash/throttle'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'
import * as ObjectPool from '@/helpers/object-pool'
import openExternalLink from '@/helpers/open-external-link'
import getPixiApp from '@/helpers/pixi'
import randomId from '@/helpers/random-id'
import { AlertModule } from '@/modules/alert/store'
import { CodeModule } from '@/modules/code/store'
import CommentMenuCreate from '@/modules/comment/components/comment-menu/create.vue'
import CommentMenuThread from '@/modules/comment/components/comment-menu/thread.vue'
import { CommentModule } from '@/modules/comment/store'
import { DomainModule } from '@/modules/domain/store'
import EditorBackButton from '@/modules/editor/components/back-button.vue'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import ModelConnectionAssignMenu from '@/modules/model/components/connections/connection-assign-menu.vue'
import ModelObjectAssignMenu from '@/modules/model/components/objects/object-assign-menu.vue'
import * as modelAnalytics from '@/modules/model/helpers/analytics'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { RouteModule } from '@/modules/route/store'
import { ShareModule } from '@/modules/share/store'
import { TagModule } from '@/modules/tag/store'
import { TeamModule } from '@/modules/team/store'
import UserGoalTooltip from '@/modules/user/components/goal-tooltip.vue'
import * as userAnalytics from '@/modules/user/helpers/analytics'
import { UserModule } from '@/modules/user/store'
import { VersionModule } from '@/modules/version/store'

import { EditorModule } from '../../editor/store'
import * as analytics from '../helpers/analytics'
import { DiagramModule } from '../store'
import CameraControls from './camera-controls.vue'

const MINUTE_SECONDS = 60

@Component({
  components: {
    CameraControls,
    CommentMenuCreate,
    CommentMenuThread,
    EditorBackButton,
    ModelConnectionAssignMenu,
    ModelObjectAssignMenu,
    UserGoalTooltip
  },
  name: 'DiagramCanvas'
})
export default class DiagramCanvas extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  codeModule = getModule(CodeModule, this.$store)
  commentModule = getModule(CommentModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  routeModule = getModule(RouteModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly containerRef!: HTMLElement
  @Ref() readonly canvasRef!: HTMLElement
  @Ref() readonly commentMenu?: CommentMenuCreate | CommentMenuThread
  @Ref() readonly zoomUserGoalRef?: UserGoalTooltip

  @Prop() readonly permission!: PermissionType
  @Prop() readonly modelObjectLimit?: number
  @Prop({ default: true }) readonly flowAnimation?: boolean

  @Prop({ default: 0 }) readonly right!: number
  @Prop({ default: 0 }) readonly left!: number

  app?: InstanceType<typeof Application<HTMLCanvasElement>> | undefined
  view?: Context.Diagram.View | undefined
  error = ''

  blurListener?: () => void

  replaceObjectIds: null | string[] = null
  replaceConnectionIds: null | string[] = null
  replaceCommentIds: null | string[] = null

  stagedObjects = {
    $add: {} as Record<string, DiagramObject>,
    $remove: {} as Record<string, true>,
    $update: {} as Record<string, DiagramObjectPartial>
  }

  stagedObjectsRevert = {
    $add: {} as Record<string, DiagramObject>,
    $remove: {} as Record<string, true>,
    $update: {} as Record<string, DiagramObjectPartial>
  }

  stagedConnections = {
    $add: {} as Record<string, DiagramConnection>,
    $remove: {} as Record<string, true>,
    $update: {} as Record<string, DiagramConnectionPartial>
  }

  stagedConnectionsRevert = {
    $add: {} as Record<string, DiagramConnection>,
    $remove: {} as Record<string, true>,
    $update: {} as Record<string, DiagramConnectionPartial>
  }

  stagedComments = {
    $add: {} as Record<string, DiagramComment>,
    $remove: {} as Record<string, true>,
    $update: {} as Record<string, DiagramCommentPartial>
  }

  stagedCommentsRevert = {
    $add: {} as Record<string, DiagramComment>,
    $remove: {} as Record<string, true>,
    $update: {} as Record<string, DiagramCommentPartial>
  }

  blurTimer?: number

  scale: number | null = null

  primaryObject: { x: number, y: number } | null = null

  creatingObjectPosition: IPoint | null = null
  creatingObjectPositionOriginModelId: string | null = null
  creatingConnectionPosition: IPoint | null = null
  creatingConnectionPositionOriginModelId: string | null = null
  creatingConnectionPositionTargetModelId: string | null = null

  commentDisabled = false
  commentPosition: IPoint | null = null
  commentPositionGlobal: IPoint | null = null

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentFlowHandleId () {
    return this.$queryValue('flow')
  }

  get currentFlowStepId () {
    return this.$queryValue('flow_step')
  }

  get currentFlowPathIds () {
    return this.$queryArray('flow_path')
  }

  get currentObjectIds () {
    return this.$queryArray('object')
  }

  get currentConnectionIds () {
    return this.$queryArray('connection')
  }

  get currentCommentIds () {
    return this.$queryArray('comment')
  }

  get commentsHidden () {
    return this.$queryValue('comments_hidden')
  }

  get overlayGroupId () {
    return this.$queryValue('overlay_group')
  }

  get overlayTab () {
    return this.$queryValue('overlay_tab')
  }

  get overlayIdsPinned () {
    return this.$queryArray('overlay_pin')
  }

  get overlayIdsHidden () {
    return this.$queryArray('overlay_hide')
  }

  get overlayIdsFocused () {
    return this.$queryArray('overlay_focus')
  }

  get drawer () {
    return this.$queryValue('drawer')
  }

  get shareLinkPreventNavigation () {
    return !!this.shareModule.shareLinkOptions?.preventNavigation && this.shareModule.shareLinkOptions.shareLinkId === this.currentShareLink?.id
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape?.organizationId)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)!
  }

  get currentDiagramContent () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)!
  }

  get currentDiagramModel () {
    return this.modelModule.objects[this.currentDiagramContent.modelId]
  }

  get currentDiagramModelFamily () {
    return [this.currentDiagramModel.id, ...this.currentDiagramModel.parentIds].map(o => this.modelModule.objects[o])
  }

  get currentFlow () {
    return Object.values(this.flowModule.flows).find(o => o.diagramId === this.currentDiagram.id && o.handleId === this.currentFlowHandleId)
  }

  get currentFlowPathSteps () {
    const currentFlow = this.currentFlow
    if (currentFlow) {
      const flow: Flow = {
        ...currentFlow,
        steps: Object
          .values(currentFlow.steps)
          .filter(o => o.type?.endsWith('-path') || !o.pathId || this.currentFlowPathIds?.includes(o.pathId) || o.pathIndex === 0)
          .reduce<Record<string, FlowStep>>((p, c) => ({
            ...p,
            [c.id]: c
          }), {})
      }
      return flow
    }
  }

  get currentFlowSteps () {
    const step = this.currentFlowStepId ? this.currentFlow?.steps[this.currentFlowStepId] : undefined
    if (
      this.currentFlow &&
      step &&
      (step.targetId || step.originId || step.viaId || step.type === 'subflow') &&
      (!step.viaId || this.currentDiagramContent.connections[step.viaId]?.modelId) &&
      (!step.targetId || this.currentDiagramContent.objects[step.targetId]) &&
      (!step.originId || this.currentDiagramContent.objects[step.originId])
    ) {
      const path = step.pathId ? this.currentFlow.steps[step.pathId] : undefined
      if (path && step.pathIndex === 0 && path.type === 'parallel-path') {
        return [
          step,
          ...Object
            .values(this.currentFlow.steps)
            .filter(o => o.index === step.index && o.pathIndex === 0)
        ]
      } else {
        return [step]
      }
    } else {
      return []
    }
  }

  get overlayItems () {
    return [
      ...Object.values({
        ...this.tagModule.tags,
        ...this.modelModule.technologies
      }),
      ...this.teamModule.teams
    ].reduce<Record<string, Tag | ModelObjectTechnology | ModelConnectionTechnology | ModelStatus | Team>>((p, c) => ({
      ...p,
      ['handleId' in c ? c.handleId : c.id]: c
    }), {
      'status-deprecated': modelStatuses.deprecated,
      'status-future': modelStatuses.future,
      'status-live': modelStatuses.live,
      'status-removed': modelStatuses.removed
    })
  }

  get overlayPinned () {
    return this.overlayIdsPinned.map(o => this.overlayItems[o]).filter(o => o)
  }

  get overlayHidden () {
    return this.overlayIdsHidden.map(o => this.overlayItems[o]).filter(o => o)
  }

  get overlayFocused () {
    return this.overlayIdsFocused.map(o => this.overlayItems[o]).filter(o => o)
  }

  get overlayHidePreview () {
    return this.editorModule.hidePreviewIds.map(o => this.overlayItems[o]).filter(o => o) || null
  }

  get overlayUnidePreview () {
    return this.editorModule.unhidePreviewIds.map(o => this.overlayItems[o]).filter(o => o) || null
  }

  get overlayFocusPreview () {
    return this.editorModule.focusPreviewIds.map(o => this.overlayItems[o]).filter(o => o) || null
  }

  get overlayUnfocusPreview () {
    return this.editorModule.unfocusPreviewIds.map(o => this.overlayItems[o]).filter(o => o) || null
  }

  get highlight () {
    return this.editorModule.highlightIds.map(o => this.overlayItems[o]).filter(o => o)
  }

  get toolbarSelection () {
    return this.editorModule.toolbarSelection
  }

  get userGoals () {
    return this.userModule.userGoals
  }

  get activeUserLocations () {
    const timestamp = Math.round(Date.now() / 1000)
    return Object
      .values(this.editorModule.locations)
      .filter(o => o.userId !== this.userModule.user?.id && o.versionId && o.publishedAt >= timestamp - MINUTE_SECONDS)
  }

  get userLocationModelIds () {
    return this.activeUserLocations.reduce<Record<string, string[]>>((p, c) => {
      const diagram = c.diagramId ? this.diagramModule.diagrams[c.diagramId] : undefined
      return {
        ...p,
        [c.userId]: diagram && diagram.status !== 'draft' ? [diagram.modelId, ...this.modelModule.objects[diagram.modelId]?.parentIds ?? []] : []
      }
    }, {})
  }

  get userLocationsCurrentDiagram () {
    return this.activeUserLocations.filter(o => o.diagramId === this.currentDiagram.id)
  }

  get objects () {
    const objects = {
      ...this.currentDiagramContent.objects,
      ...this.stagedObjects.$add
    }
    Object.entries(this.stagedObjects.$update).forEach(([id, props]) => {
      Object.assign(objects[id], props)
    })
    Object.keys(this.stagedObjects.$remove).forEach(o => {
      delete objects[o]
    })

    interface Object {
      activeUserIds: string[]
      colors: TagColor[]
      domain: Domain | null
      inScope: boolean
      locked: boolean
      model: ModelObject
      object: DiagramObject
      pinned: (Tag | ModelObjectTechnology | ModelStatus | Team)[] | null
      pinnedIcon: TagGroupIcon | null
      pinnedKey: string
    }

    const userLocationModelIds = this.userLocationModelIds
    const userLocationsCurrentDiagram = this.userLocationsCurrentDiagram
    const currentDiagramModelFamily = this.currentDiagramModelFamily
    const currentDiagramModel = this.currentDiagramModel
    const overlayPinned = this.overlayPinned

    return Object.entries(objects).reduce<Record<string, Object>>((p, [id, object]) => {
      const model = this.modelModule.objects[object.modelId]
      if (model) {
        const parents = this.modelModule.objects[object.modelId]?.parentIds.map(o => this.modelModule.objects[o]) || []
        const pinned = overlayPinned.filter(o => `status-${model.status}` === o.id || model.tagIds.includes(o.id) || !!model.technologies[o.id] || model.teamIds.includes(o.id))
        const inScope = currentDiagramModelFamily.some(o => object.shape === 'area' ? o.id === model.id : o.id === model.parentId)
        const domain = currentDiagramModel && model.domainId !== currentDiagramModel.domainId ? this.domainModule.domains[model.domainId] : null
        const external = this.modelModule.objects[object.modelId].external || parents.some(o => o.external)

        const statusLockParent = [this.modelModule.objects[object.modelId], ...parents].reverse().find(o => o.status === 'future' || o.status === 'deprecated' || o.status === 'removed')
        const status = statusLockParent ? statusLockParent.status : model.status

        let colors: TagColor[] = []
        let pinnedIcon: TagGroupIcon | null = null

        if (this.overlayTab === 'tags' && this.overlayGroupId) {
          const tagGroup = Object.values(this.tagModule.tagGroups).find(o => o.handleId === this.overlayGroupId)
          pinnedIcon = tagGroup?.icon || null
          colors = [...new Set(
            model.tagIds
              .filter(o => this.tagModule.tags[o]?.groupId === tagGroup?.id)
              .map(o => this.tagModule.tags[o]?.color)
              .filter((o): o is TagColor => !!o)
          )]
        } else if (this.overlayTab === 'technology' && this.overlayGroupId) {
          colors = [...new Set(Object.values(model.technologies).map(o => o.color))]
        } else if (this.overlayTab === 'status' && this.overlayGroupId) {
          colors = [modelStatuses[status].color]
        } else if (this.overlayTab === 'teams' && this.overlayGroupId) {
          pinnedIcon = 'users'
          colors = [...new Set(
            model.teamIds
              .map(o => this.teamModule.teams.find(t => t.id === o)?.color)
              .filter((o): o is TagColor => !!o)
          )]
        }

        let activeUserIds: string[]
        if (this.currentDiagram.status === 'draft') {
          activeUserIds = object.shape === 'area' ? userLocationsCurrentDiagram.map(o => o.userId) : []
        } else {
          activeUserIds = Object
            .entries(userLocationModelIds)
            .filter(([, modelIds]) => object.shape === 'area' ? modelIds[0] === object.modelId : modelIds.includes(object.modelId))
            .map(([userId]) => userId)
        }

        p[id] = {
          activeUserIds,
          colors,
          domain,
          inScope,
          locked: model ? this.isModelObjectProtected(model.id) : false,
          model: {
            ...model,
            external,
            status
          },
          object,
          pinned,
          pinnedIcon,
          pinnedKey: pinned.map(o => 'version' in o ? `${o.id}-${o.version}` : o.id).join('-')
        }

        return p
      } else {
        return p
      }
    }, {})
  }

  get connections () {
    const connections = {
      ...this.currentDiagramContent.connections,
      ...this.stagedConnections.$add
    }
    Object.entries(this.stagedConnections.$update).forEach(([id, props]) => {
      Object.assign(connections[id], props)
    })
    Object.keys(this.stagedConnections.$remove).forEach(o => {
      delete connections[o]
    })

    interface Connection {
      color: TagColor | null
      connection: DiagramConnection
      locked: boolean
      lower: boolean
      model: ModelConnection | null
      overlay: TagGroup | ModelConnectionTechnology | ModelStatus | null
      overlayKey: string
      pinned: (Tag | ModelConnectionTechnology | ModelStatus | Team)[] | null
      pinnedKey: string
    }

    const modelObjects = this.modelModule.objects
    const modelConnections = this.modelModule.connections
    const diagramObjects = this.objects
    const overlayPinned = this.overlayPinned

    return Object.entries(connections).reduce<Record<string, Connection>>((p, [id, connection]) => {
      if (
        (!connection.modelId || modelConnections[connection.modelId]) &&
        (!connection.originId || diagramObjects[connection.originId]) &&
        (!connection.targetId || diagramObjects[connection.targetId])
      ) {
        let model = connection.modelId ? modelConnections[connection.modelId] : null

        let overlay: TagGroup | ModelConnectionTechnology | ModelStatus | null = null
        let color: TagColor | null = null

        let lower: boolean
        if (model) {
          const originModelObjectParents = modelObjects[model.originId]?.parentIds.map(o => modelObjects[o]) || []
          const targetModelObjectParents = modelObjects[model.targetId]?.parentIds.map(o => modelObjects[o]) || []

          const originStatusLockParent = [modelObjects[model.originId], ...originModelObjectParents].reverse().find(o => o.status === 'future' || o.status === 'deprecated' || o.status === 'removed')
          const targetStatusLockParent = [modelObjects[model.targetId], ...targetModelObjectParents].reverse().find(o => o.status === 'future' || o.status === 'deprecated' || o.status === 'removed')

          if (originStatusLockParent?.status === 'removed' || targetStatusLockParent?.status === 'removed') {
            model = {
              ...model,
              status: 'removed'
            }
          } else if (originStatusLockParent?.status === 'deprecated' || targetStatusLockParent?.status === 'deprecated') {
            model = {
              ...model,
              status: 'deprecated'
            }
          } else if (originStatusLockParent?.status === 'future' || targetStatusLockParent?.status === 'future') {
            model = {
              ...model,
              status: 'future'
            }
          }

          if (this.overlayTab === 'tags' && this.overlayGroupId) {
            const tagGroup = Object.values(this.tagModule.tagGroups).find(o => o.handleId === this.overlayGroupId) || null
            overlay = tagGroup
            color = model.tagIds.filter(o => this.tagModule.tags[o]?.groupId === tagGroup?.id).map(o => this.tagModule.tags[o]?.color)?.[0] || null
          } else if (this.overlayTab === 'technology' && this.overlayGroupId) {
            const firstTechnology = Object.values(model.technologies).sort((a, b) => a.index - b.index)?.[0] || null
            overlay = firstTechnology
            color = firstTechnology?.color || null
          } else if (this.overlayTab === 'status' && this.overlayGroupId) {
            overlay = modelStatuses[model.status]
            color = modelStatuses[model.status].color
          }

          const diagramConnection = model.diagrams[this.currentDiagramContent.id]
          lower = diagramConnection && (model.originId !== diagramConnection.originModelId || model.targetId !== diagramConnection.targetModelId)
        } else {
          lower = false
        }

        const pinned = overlayPinned.filter(o => `status-${model?.status}` === o.id || model?.tagIds.includes(o.id) || !!model?.technologies[o.id])

        p[id] = {
          color,
          connection,
          locked: model ? this.isModelObjectProtected(model.originId) : false,
          lower,
          model,
          overlay,
          overlayKey: '',
          pinned,
          pinnedKey: pinned.map(o => 'versionId' in o ? `${o.id}-${o.version}` : o.id).join('-')
        }

        return p
      } else {
        return p
      }
    }, {})
  }

  get comments () {
    const comments = {
      ...this.currentDiagramContent.comments,
      ...this.stagedComments.$add
    }
    Object.entries(this.stagedComments.$update).forEach(([id, props]) => {
      Object.assign(comments[id], props)
    })
    Object.keys(this.stagedComments.$remove).forEach(o => {
      delete comments[o]
    })

    const commentObjects: Record<string, { comment: Comment, diagramComment: DiagramComment }> = {}

    if (!this.commentsHidden && !this.currentShareLink && this.currentVersionId === 'latest') {
      Object.entries(comments).forEach(([id, diagramComment]) => {
        const comment = this.commentModule.activeComments[diagramComment.commentId]
        if (comment) {
          commentObjects[id] = {
            comment,
            diagramComment
          }
        }
      })
    }

    const newComment = this.currentCommentIds.find(o => o.startsWith('new')) as Exclude<CommentBodyType, 'idea' | 'question' | 'inaccurate'> | undefined
    if (newComment && this.view) {
      const oldComment = this.view.comments['new-idea'] || this.view.comments['new-question'] || this.view.comments['new-inaccurate']
      commentObjects[newComment] = {
        comment: {
          body: {
            content: '',
            status: 'create',
            type: newComment
          },
          commit: 0,
          createdAt: '',
          createdBy: 'user',
          createdById: '',
          diagrams: {},
          handleId: newComment,
          id: 'new',
          landscapeId: '',
          mentionedUserIds: [],
          replyCount: 0,
          updatedAt: '',
          updatedBy: 'user',
          updatedById: '',
          version: 0,
          versionId: ''
        },
        diagramComment: {
          commentId: 'new',
          id: newComment,
          x: oldComment?.x ?? snapToGrid(this.view.sceneCenter.x, 4),
          y: oldComment?.y ?? snapToGrid(this.view.sceneCenter.y, 4)
        }
      }
    }

    return commentObjects
  }

  get diagramElements () {
    return {
      comments: this.comments,
      connections: this.connections,
      objects: this.objects
    }
  }

  get currentComment () {
    return this.currentCommentIds.length === 1 ? Object.values(this.comments).find(o => this.currentCommentIds.includes(o.diagramComment.id)) : undefined
  }

  get stepHoverIds () {
    return this.editorModule.stepHoverIds
  }

  /**
   * left and right must be first watchers so resize calls happen first
   */

  @Watch('left')
  onLeftChanged (left: number, prevLeft: number) {
    this.view?.setPan({
      x: this.view.pan.x - (left - prevLeft),
      y: this.view.pan.y
    })

    this.resize()
  }

  @Watch('right')
  onRightChanged () {
    this.resize()
  }

  @Watch('currentDiagram')
  onCurrentDiagramChanged (diagram: Diagram, prevDiagram?: Diagram) {
    if (this.view) {
      if (diagram.status !== prevDiagram?.status || diagram.id !== prevDiagram?.id) {
        Object.keys(this.view.objects).forEach(o => this.removeObject(o))
        Object.keys(this.view.connections).forEach(o => this.removeConnection(o))
        Object.keys(this.view.comments).forEach(o => this.removeComment(o))

        this.view.objects = {}
        this.view.connections = {}
        this.view.comments = {}

        this.view.props.preventNavigation = this.shareLinkPreventNavigation || diagram.status === 'draft'

        Object.values(this.objects).forEach(o => this.upsertObject(o))
        Object.values(this.connections).forEach(o => this.upsertConnection(o))
        Object.values(this.comments).forEach(o => this.upsertComment(o))

        Object.values(this.objects).forEach(o => this.initObject(o.object.id))
        Object.values(this.connections).forEach(o => this.initConnection(o.connection.id))
        Object.values(this.comments).forEach(o => this.initComment(o.diagramComment.id))
      }
    }
  }

  @Watch('currentDiagramContent')
  onCurrentDiagramContentChanged (diagramContent: DiagramContent, prevDiagramContent?: DiagramContent) {
    if (this.view) {
      if (diagramContent.id !== prevDiagramContent?.id) {
        this.updateQueryDebounce.cancel()

        this.view.objectCreate?.destroy()
        this.view.objectCreate = undefined

        this.view.diagram.setModel(diagramContent, true)

        this.restoreCameraPosition()
      } else {
        this.view?.diagram.setModel(diagramContent)
      }
    }
  }

  @Watch('diagramElements')
  onDiagramElementsChanged (
    { comments, connections, objects }: { comments: DiagramCanvas['comments'], connections: DiagramCanvas['connections'], objects: DiagramCanvas['objects'] },
    { comments: prevComments, connections: prevConnections, objects: prevObjects }: { comments: DiagramCanvas['comments'], connections: DiagramCanvas['connections'], objects: DiagramCanvas['objects'] }
  ) {
    Object
      .keys(objects)
      .filter(o => prevObjects[o] && (objects[o].object.type !== prevObjects[o].object.type || objects[o].object.shape !== prevObjects[o].object.shape))
      .forEach(o => this.removeObject(o))

    const removeObjectIds = Object
      .keys(prevObjects)
      .filter(o => !objects[o])
      .map(o => {
        this.removeObject(o)
        return o
      })

    const removeConnectionIds = Object
      .keys(prevConnections)
      .filter(o => !connections[o])
      .map(o => {
        this.removeConnection(o)
        return o
      })

    Object
      .keys(prevComments)
      .filter(o => !comments[o])
      .forEach(o => this.removeComment(o))

    Object
      .entries(objects)
      .forEach(([id, o]) => this.upsertObject(o, prevObjects[id]))

    Object
      .entries(connections)
      .forEach(([id, o]) => this.upsertConnection(o, prevConnections[id]))

    Object
      .values(comments)
      .forEach(o => this.upsertComment(o))

    const updateTypeObjectIds = Object
      .keys(objects)
      .filter(o => prevObjects[o] && (objects[o].object.type !== prevObjects[o].object.type || objects[o].object.shape !== prevObjects[o].object.shape))
      .map(o => {
        this.initObject(o)
        return o
      })

    const addedObjectIds = Object
      .keys(objects)
      .filter(o => !prevObjects[o])
      .map(id => {
        this.initObject(id)
        return id
      })

    const addedConnectionIds = Object
      .keys(connections)
      .filter(o => !prevConnections[o])
      .map(id => {
        this.initConnection(id)
        return id
      })

    Object
      .keys(comments)
      .filter(o => !prevComments[o])
      .forEach(o => this.initComment(o))

    if (
      (removeObjectIds.length && this.currentObjectIds.some(o => removeObjectIds.includes(o))) ||
      (removeConnectionIds.length && this.currentConnectionIds.some(o => removeConnectionIds.includes(o)))
    ) {
      this.$replaceQuery({
        connection: this.$removeQueryArray(...removeConnectionIds),
        object: this.$removeQueryArray(...removeObjectIds)
      })
    }

    if (
      (updateTypeObjectIds.length && this.currentObjectIds.some(o => updateTypeObjectIds.includes(o))) ||
      (removeObjectIds.length && this.currentObjectIds.some(o => removeObjectIds.includes(o))) ||
      (removeConnectionIds.length && this.currentConnectionIds.some(o => removeConnectionIds.includes(o))) ||
      addedObjectIds.length || addedConnectionIds.length
    ) {
      Object.values(this.view?.objects || {}).forEach(o => o.updateStyle())
      Object.values(this.view?.connections || {}).forEach(o => o.updateStyle())
    }
  }

  @Watch('currentObjectIds')
  onCurrentObjectIdsChanged (currentObjectIds: string[], prevCurrentObjectIds: string[]) {
    const selectedObjectIds = currentObjectIds
      .filter(o => !prevCurrentObjectIds.includes(o))
      .map(o => {
        this.view?.objects[o]?.setSelected(true)
        return o
      })

    const unselectedObjectIds = prevCurrentObjectIds
      .filter(o => !currentObjectIds.includes(o))
      .map(o => {
        this.view?.objects[o]?.setSelected(false)
        return o
      })

    if (selectedObjectIds.length || unselectedObjectIds.length) {
      Object.values(this.view?.objects || {}).forEach(o => o.updateStyle())
      Object.values(this.view?.connections || {}).forEach(o => o.updateStyle())
    }
  }

  @Watch('currentConnectionIds')
  onCurrentConnectionIdsChanged (currentConnectionIds: string[], prevCurrentConnectionIds: string[]) {
    const selectedConnectionIds = currentConnectionIds
      .filter(o => !prevCurrentConnectionIds.includes(o))
      .map(o => {
        this.view?.connections[o]?.setSelected(true)
        return o
      })

    const unselectedConnectionIds = prevCurrentConnectionIds
      .filter(o => !currentConnectionIds.includes(o))
      .map(o => {
        this.view?.connections[o]?.setSelected(false)
        return o
      })

    if (selectedConnectionIds.length || unselectedConnectionIds.length) {
      Object.values(this.view?.objects || {}).forEach(o => o.updateStyle())
      Object.values(this.view?.connections || {}).forEach(o => o.updateStyle())
    }
  }

  @Watch('currentCommentIds')
  onCurrentCommentIdsChanged (currentCommentIds: string[], prevCurrentCommentIds: string[]) {
    currentCommentIds
      .filter(o => !prevCurrentCommentIds.includes(o))
      .forEach(o => Object.values(this.view?.comments || {}).find(c => c.id === o)?.setSelected(true))

    prevCurrentCommentIds
      .filter(o => !currentCommentIds.includes(o))
      .forEach(o => Object.values(this.view?.comments || {}).find(c => c.id === o)?.setSelected(false))

    this.findCommentThrottle()
  }

  @Watch('currentFlow')
  onCurrentFlowChanged () {
    this.findPrimaryObjects()
  }

  @Watch('currentFlowPathSteps')
  onCurrentFlowPathStepsChanged (currentFlowPathSteps?: Flow) {
    this.view?.flow.setModel(currentFlowPathSteps || null, true)
  }

  @Watch('currentComment')
  onCurrentCommentChanged (currentComment?: DiagramCanvas['comments'][0], prevCurrentComment?: DiagramCanvas['comments'][0]) {
    if (this.view && currentComment && currentComment.diagramComment.id !== prevCurrentComment?.diagramComment.id && !pointInRect(currentComment.diagramComment, this.view.sceneRect)) {
      this.view.transitionState(new Context.Diagram.State.Teleport({
        comments: [currentComment.diagramComment]
      }))
    }
  }

  @Watch('flowAnimation')
  onFlowAnimationChanged (flowAnimation: boolean, prevFlowAnimation: boolean) {
    if (this.currentFlow && flowAnimation && !prevFlowAnimation) {
      this.resetCameraPosition()
    }
  }

  @Watch('currentFlowSteps')
  onCurrentFlowStepsChanged (currentFlowSteps: FlowStep[], prevCurrentFlowSteps: FlowStep[]) {
    this.view?.flow.setStore(currentFlowSteps)

    if (this.flowAnimation) {
      const viaIds = currentFlowSteps.map(o => o.viaId)
      const viaIdsPrev = prevCurrentFlowSteps.map(o => o.viaId)

      const originIds = currentFlowSteps.map(o => o.originId)
      const originIdsPrev = prevCurrentFlowSteps.map(o => o.originId)

      const targetIds = currentFlowSteps.map(o => o.targetId)
      const targetIdsPrev = prevCurrentFlowSteps.map(o => o.targetId)

      if (!isEqual(viaIds, viaIdsPrev) || !isEqual(originIds, originIdsPrev) || !isEqual(targetIds, targetIdsPrev)) {
        this.view?.transitionState(new Context.Diagram.State.Teleport({
          connections: Object.values(this.connections).filter(o => viaIds.includes(o.connection.id)).map(o => o.connection),
          objects: Object.values(this.objects).filter(o => originIds.includes(o.object.id) || targetIds.includes(o.object.id)).map(o => o.object)
        }))
      }
    }
  }

  @Watch('overlayHidden')
  onOverlayHiddenChanged (overlayHidden: (Tag | ModelObjectTechnology | ModelStatus)[]) {
    if (this.view) {
      this.view.hideIds = overlayHidden.map(o => o.id)
    }
  }

  @Watch('overlayFocused')
  onOverlayFocusedChanged (overlayFocused: (Tag | ModelObjectTechnology | ModelStatus)[]) {
    if (this.view) {
      this.view.focusIds = overlayFocused.map(o => o.id)
    }
  }

  @Watch('overlayHidePreview')
  onOverlayHidePreviewChanged (overlayHidePreview: (Tag | ModelObjectTechnology | ModelStatus)[]) {
    if (this.view) {
      this.view.hidePreviewIds = overlayHidePreview.map(o => o.id)
    }
  }

  @Watch('overlayUnidePreview')
  onOverlayUnhidePreviewChanged (overlayUnhidePreview: (Tag | ModelObjectTechnology | ModelStatus)[]) {
    if (this.view) {
      this.view.unhidePreviewIds = overlayUnhidePreview.map(o => o.id)
    }
  }

  @Watch('overlayFocusPreview')
  onOverlayFocusPreviewChanged (overlayFocusPreview: (Tag | ModelObjectTechnology | ModelStatus)[]) {
    if (this.view) {
      this.view.focusPreviewIds = overlayFocusPreview.map(o => o.id)
    }
  }

  @Watch('overlayUnfocusPreview')
  onOverlayUnfocusPreviewChanged (overlayUnfocusPreview: (Tag | ModelObjectTechnology | ModelStatus)[]) {
    if (this.view) {
      this.view.unfocusPreviewIds = overlayUnfocusPreview.map(o => o.id)
    }
  }

  @Watch('highlight')
  onHighlightChanged (highlight: (Tag | ModelObjectTechnology | ModelStatus)[]) {
    if (this.view) {
      this.view.highlight = highlight[0] || null
    }
  }

  @Watch('stepHoverIds')
  onStepHoverIdsChanged (stepHoverIds: string[], prevStepHoverIds: string[]) {
    stepHoverIds
      .filter(o => !prevStepHoverIds.includes(o))
      .forEach(a => {
        const obj = this.view?.objects[a]
        const con = this.view?.connections[a]
        obj?.setHover(true)
        con?.connectionLine.setHover(true)
      })

    prevStepHoverIds
      .filter(o => !stepHoverIds.includes(o))
      .forEach(a => {
        const obj = this.view?.objects[a]
        const con = this.view?.connections[a]
        obj?.setHover(false)
        con?.connectionLine.setHover(false)
      })
  }

  @Watch('toolbarSelection')
  onToolbarSelectionChanged (toolbarSelection: DiagramTool) {
    if (this.view) {
      this.view.tool = toolbarSelection
    }
  }

  @Watch('drawer')
  onDrawerChanged () {
    this.findPrimaryObjects()
  }

  @Watch('userGoals')
  onUserGoalsChanged () {
    this.findPrimaryObjectsThrottle()
  }

  async mounted () {
    try {
      this.app = getPixiApp()
      this.app.resizeTo = this.containerRef

      this.view = new Context.Diagram.View(this.app, this.currentDiagramContent, {
        focusIds: this.overlayFocused.map(o => o.id),
        hideIds: this.overlayHidden.map(o => o.id),
        iconUrl: env.ICON_URL,
        lineShapeDefault: this.currentOrganization?.lineShapeDefault,
        modelObjectLimit: this.modelObjectLimit,
        permission: this.permission,
        preventNavigation: this.shareLinkPreventNavigation || this.currentDiagram.status === 'draft',
        users: this.organizationModule.organizationUsers[this.currentLandscape.organizationId]
      }, {
        getModelConnections: () => this.modelModule.connections,
        getModelObjects: () => this.modelModule.objects,
        getRoute: props => ({
          name: props?.name || this.$route.name,
          params: {
            ...this.$route.params,
            ...props?.params
          },
          query: {
            ...this.$route.query,
            ...props?.query
          }
        }),
        modelConnectionCreate: props => {
          const { connection, connectionUpsert } = this.modelModule.generateConnection(this.currentLandscape.id, this.currentVersion.id, props)

          if (this.currentDiagram.status === 'draft') {
            const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
              tasksProposed: {
                $append: [{
                  id: connection.id,
                  props: connectionUpsert,
                  type: 'model-connection-create'
                }]
              }
            })
            this.diagramModule.setDiagramContentVersion(diagramContent)
            this.editorModule.addToTaskQueue({
              func: () => this.diagramModule.diagramContentUpdate({
                diagramId: diagramContent.id,
                landscapeId: this.currentLandscape.id,
                props: diagramContentUpdate,
                versionId: this.currentVersion.id
              })
            })

            return {
              connection,
              revertTask: {
                id: this.currentDiagram.id,
                props: {
                  tasksProposed: {
                    $append: [{
                      id: connection.id,
                      type: 'model-connection-delete'
                    }]
                  }
                },
                type: 'diagram-content-update'
              },
              task: {
                id: this.currentDiagram.id,
                props: diagramContentUpdate,
                type: 'diagram-content-update'
              }
            }
          } else {
            this.modelModule.setConnection(connection)
            this.editorModule.addToTaskQueue({
              func: () => this.modelModule.connectionUpsert({
                connectionId: connection.id,
                landscapeId: this.currentLandscape.id,
                props: connectionUpsert,
                versionId: this.currentVersion.id
              })
            })
          }

          return {
            connection,
            revertTask: {
              id: connection.id,
              type: 'model-connection-delete'
            },
            task: {
              id: connection.id,
              props: connectionUpsert,
              type: 'model-connection-create'
            }
          }
        },
        modelConnectionDelete: connectionId => {
          if (this.currentDiagram.status === 'draft') {
            const revertTask: Task = {
              id: this.currentDiagram.id,
              props: {
                tasksProposed: {
                  $append: [{
                    id: connectionId,
                    props: {
                      ...this.modelModule.connections[connectionId],
                      commit: undefined
                    },
                    type: 'model-connection-create'
                  }]
                }
              },
              type: 'diagram-content-update'
            }

            const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
              tasksProposed: {
                $append: [{
                  id: connectionId,
                  type: 'model-connection-delete'
                }]
              }
            })
            this.diagramModule.setDiagramContentVersion(diagramContent)
            this.editorModule.addToTaskQueue({
              func: () => this.diagramModule.diagramContentUpdate({
                diagramId: diagramContent.id,
                landscapeId: this.currentLandscape.id,
                props: diagramContentUpdate,
                versionId: this.currentVersion.id
              })
            })

            return {
              revertTask,
              task: {
                id: this.currentDiagram.id,
                props: diagramContentUpdate,
                type: 'diagram-content-update'
              }
            }
          } else {
            const revertTask: Task = {
              id: connectionId,
              props: {
                ...this.modelModule.connections[connectionId],
                commit: undefined
              },
              type: 'model-connection-create'
            }

            this.modelModule.removeConnection(connectionId)
            this.editorModule.addToTaskQueue({
              func: () => this.modelModule.connectionDelete({
                connectionId,
                landscapeId: this.currentLandscape.id,
                versionId: this.currentVersion.id
              })
            })

            return {
              revertTask,
              task: {
                id: connectionId,
                type: 'model-connection-delete'
              }
            }
          }
        },
        modelConnectionUpdate: (connectionId, props) => {
          const { connection, connectionUpdate } = this.modelModule.generateConnectionCommit(connectionId, props)

          if (this.currentDiagram.status === 'draft') {
            const revertTask: Task = {
              id: this.currentDiagram.id,
              props: pick(this.modelModule.connections[connectionId], Object.keys(props)),
              type: 'diagram-content-update'
            }

            const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
              tasksProposed: {
                $append: [{
                  id: connection.id,
                  props: connectionUpdate,
                  type: 'model-connection-update'
                }]
              }
            })
            this.diagramModule.setDiagramContentVersion(diagramContent)
            this.editorModule.addToTaskQueue({
              func: () => this.diagramModule.diagramContentUpdate({
                diagramId: diagramContent.id,
                landscapeId: this.currentLandscape.id,
                props: diagramContentUpdate,
                versionId: this.currentVersion.id
              })
            })

            return {
              connection,
              revertTask,
              task: {
                id: this.currentDiagram.id,
                props: diagramContentUpdate,
                type: 'diagram-content-update'
              }
            }
          } else {
            const revertTask: Task = {
              id: connectionId,
              props: pick(this.modelModule.connections[connectionId], Object.keys(props)),
              type: 'model-connection-update'
            }

            this.modelModule.setConnectionVersion(connection)
            this.editorModule.addToTaskQueue({
              func: () => this.modelModule.connectionUpdate({
                connectionId,
                landscapeId: this.currentLandscape.id,
                props: connectionUpdate,
                versionId: this.currentVersion.id
              })
            })

            return {
              connection,
              revertTask,
              task: {
                id: connectionId,
                props,
                type: 'model-connection-update'
              }
            }
          }
        },
        modelObjectCreate: props => {
          const { object, objectUpsert } = this.modelModule.generateObject(this.currentLandscape.id, this.currentVersion.id, {
            ...props,
            domainId: this.currentDiagramModel.domainId
          })

          if (this.currentDiagram.status === 'draft') {
            const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
              tasksProposed: {
                $append: [{
                  id: object.id,
                  props: objectUpsert,
                  type: 'model-object-create'
                }]
              }
            })
            this.diagramModule.setDiagramContentVersion(diagramContent)
            this.editorModule.addToTaskQueue({
              func: () => this.diagramModule.diagramContentUpdate({
                diagramId: diagramContent.id,
                landscapeId: this.currentLandscape.id,
                props: diagramContentUpdate,
                versionId: this.currentVersion.id
              })
            })

            return {
              object,
              revertTask: {
                id: this.currentDiagram.id,
                props: {
                  tasksProposed: {
                    $append: [{
                      id: object.id,
                      type: 'model-object-delete'
                    }]
                  }
                },
                type: 'diagram-content-update'
              },
              task: {
                id: this.currentDiagram.id,
                props: diagramContentUpdate,
                type: 'diagram-content-update'
              }
            }
          } else {
            this.modelModule.setObject(object)
            this.editorModule.addToTaskQueue({
              func: () => this.modelModule.objectUpsert({
                landscapeId: this.currentLandscape.id,
                objectId: object.id,
                props: objectUpsert,
                versionId: this.currentVersion.id
              })
            })

            return {
              object,
              revertTask: {
                id: object.id,
                type: 'model-object-delete'
              },
              task: {
                id: object.id,
                props: objectUpsert,
                type: 'model-object-create'
              }
            }
          }
        },
        modelObjectDelete: objectId => {
          if (this.currentDiagram.status === 'draft') {
            const revertTask: Task = {
              id: this.currentDiagram.id,
              props: {
                tasksProposed: {
                  $append: [{
                    id: objectId,
                    props: {
                      ...this.modelModule.objects[objectId],
                      commit: undefined
                    },
                    type: 'model-object-create'
                  }]
                }
              },
              type: 'diagram-content-update'
            }

            const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
              tasksProposed: {
                $append: [{
                  id: objectId,
                  type: 'model-object-delete'
                }]
              }
            })
            this.diagramModule.setDiagramContentVersion(diagramContent)
            this.editorModule.addToTaskQueue({
              func: () => this.diagramModule.diagramContentUpdate({
                diagramId: diagramContent.id,
                landscapeId: this.currentLandscape.id,
                props: diagramContentUpdate,
                versionId: this.currentVersion.id
              })
            })

            return {
              revertTask,
              task: {
                id: this.currentDiagram.id,
                props: diagramContentUpdate,
                type: 'diagram-content-update'
              }
            }
          } else {
            const revertTask: Task = {
              id: objectId,
              props: {
                ...this.modelModule.objects[objectId],
                commit: undefined
              },
              type: 'model-object-create'
            }

            this.modelModule.removeObject(objectId)
            this.editorModule.addToTaskQueue({
              func: () => this.modelModule.objectDelete({
                landscapeId: this.currentLandscape.id,
                objectId,
                versionId: this.currentVersion.id
              })
            })

            return {
              revertTask,
              task: {
                id: objectId,
                type: 'model-object-delete'
              }
            }
          }
        },
        modelObjectUpdate: (objectId, props) => {
          const { object, objectUpdate } = this.modelModule.generateObjectCommit(objectId, props)

          if (this.currentDiagram.status === 'draft') {
            const revertTask: Task = {
              id: this.currentDiagram.id,
              props: pick(this.modelModule.objects[objectId], Object.keys(props)),
              type: 'diagram-content-update'
            }

            const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
              tasksProposed: {
                $append: [{
                  id: object.id,
                  props: objectUpdate,
                  type: 'model-object-update'
                }]
              }
            })
            this.diagramModule.setDiagramContentVersion(diagramContent)
            this.editorModule.addToTaskQueue({
              func: () => this.diagramModule.diagramContentUpdate({
                diagramId: diagramContent.id,
                landscapeId: this.currentLandscape.id,
                props: diagramContentUpdate,
                versionId: this.currentVersion.id
              })
            })

            return {
              object,
              revertTask,
              task: {
                id: this.currentDiagram.id,
                props: diagramContentUpdate,
                type: 'diagram-content-update'
              }
            }
          } else {
            this.modelModule.setObjectVersion(object)
            this.editorModule.addToTaskQueue({
              func: () => this.modelModule.objectUpdate({
                landscapeId: this.currentLandscape.id,
                objectId,
                props: objectUpdate,
                versionId: this.currentVersion.id
              })
            })

            const revertTask: Task = {
              id: objectId,
              props: pick(this.modelModule.objects[objectId], Object.keys(props)),
              type: 'model-object-update'
            }

            return {
              object,
              revertTask,
              task: {
                id: objectId,
                props,
                type: 'model-object-update'
              }
            }
          }
        },
        stageCommentCreate: props => {
          const comment: DiagramComment = {
            ...props,
            id: randomId()
          }
          Vue.set(this.stagedComments.$add, comment.id, comment)
          Vue.set(this.stagedCommentsRevert.$remove, comment.id, true)
          const res = this.upsertComment(this.comments[comment.id])
          this.initComment(res.id)
          return res
        },
        stageCommentDelete: commentId => {
          Vue.set(this.stagedCommentsRevert.$add, commentId, this.comments[commentId].diagramComment)
          Vue.set(this.stagedComments.$remove, commentId, true)
          this.removeComment(commentId)
        },
        stageCommentUpdate: (commentId, props) => {
          const comment: DiagramCommentPartial = {
            ...this.stagedComments.$update[commentId],
            ...props
          }
          const commentRevert: DiagramCommentPartial = {
            ...this.stagedCommentsRevert.$update[commentId],
            ...pick(this.comments[commentId].diagramComment, ...Object.keys(props))
          }
          Vue.set(this.stagedComments.$update, commentId, comment)
          Vue.set(this.stagedCommentsRevert.$update, commentId, commentRevert)
          return this.upsertComment(this.comments[commentId])
        },
        stageConnectionCreate: props => {
          const connection: DiagramConnection = {
            ...props,
            id: randomId()
          }
          Vue.set(this.stagedConnections.$add, connection.id, connection)
          Vue.set(this.stagedConnectionsRevert.$remove, connection.id, true)
          const res = this.upsertConnection(this.connections[connection.id])
          this.initConnection(res.id)
          return res
        },
        stageConnectionDelete: connectionId => {
          Vue.set(this.stagedConnectionsRevert.$add, connectionId, this.connections[connectionId].connection)
          Vue.set(this.stagedConnections.$remove, connectionId, true)
          this.removeConnection(connectionId)
        },
        stageConnectionUpdate: (connectionId, props) => {
          const connection: DiagramConnectionPartial = {
            ...this.stagedConnections.$update[connectionId],
            ...props
          }
          const connectionRevert: DiagramConnectionPartial = {
            ...this.stagedConnectionsRevert.$update[connectionId],
            ...pick(this.connections[connectionId].connection, ...Object.keys(props))
          }
          Vue.set(this.stagedConnections.$update, connectionId, connection)
          Vue.set(this.stagedConnectionsRevert.$update, connectionId, connectionRevert)
          return this.upsertConnection(this.connections[connectionId])
        },
        stageObjectCreate: props => {
          const object: DiagramObject = {
            ...props,
            id: randomId()
          }
          Vue.set(this.stagedObjects.$add, object.id, object)
          Vue.set(this.stagedObjectsRevert.$remove, object.id, true)
          const res = this.upsertObject(this.objects[object.id])
          this.initObject(res.id)
          return res
        },
        stageObjectDelete: objectId => {
          Vue.set(this.stagedObjectsRevert.$add, objectId, this.objects[objectId].object)
          Vue.set(this.stagedObjects.$remove, objectId, true)
          this.removeObject(objectId)
        },
        stageObjectUpdate: (objectId, props) => {
          const object: DiagramObjectPartial = {
            ...this.stagedObjects.$update[objectId],
            ...props
          }
          const objectRevert: DiagramObjectPartial = {
            ...this.stagedObjectsRevert.$update[objectId],
            ...pick(this.objects[objectId].object, ...Object.keys(props))
          }
          Vue.set(this.stagedObjects.$update, objectId, object)
          Vue.set(this.stagedObjectsRevert.$update, objectId, objectRevert)
          return this.upsertObject(this.objects[objectId])
        },
        taskListAdd: props => this.editorModule.addTaskList(props),
        updateStagedDiagram: () => {
          const objectsAddLength = Object.keys(this.stagedObjects.$add).length
          const objectsUpdateLength = Object.keys(this.stagedObjects.$update).length
          const objectsRemoveLength = Object.keys(this.stagedObjects.$remove).length

          const objectsRevertAddLength = Object.keys(this.stagedObjectsRevert.$add).length
          const objectsRevertUpdateLength = Object.keys(this.stagedObjectsRevert.$update).length
          const objectsRevertRemoveLength = Object.keys(this.stagedObjectsRevert.$remove).length

          const connectionsAddLength = Object.keys(this.stagedConnections.$add).length
          const connectionsUpdateLength = Object.keys(this.stagedConnections.$update).length
          const connectionsRemoveLength = Object.keys(this.stagedConnections.$remove).length

          const connectionsRevertAddLength = Object.keys(this.stagedConnectionsRevert.$add).length
          const connectionsRevertUpdateLength = Object.keys(this.stagedConnectionsRevert.$update).length
          const connectionsRevertRemoveLength = Object.keys(this.stagedConnectionsRevert.$remove).length

          const commentsAddLength = Object.keys(this.stagedComments.$add).length
          const commentsUpdateLength = Object.keys(this.stagedComments.$update).length
          const commentsRemoveLength = Object.keys(this.stagedComments.$remove).length

          const commentsRevertAddLength = Object.keys(this.stagedCommentsRevert.$add).length
          const commentsRevertUpdateLength = Object.keys(this.stagedCommentsRevert.$update).length
          const commentsRevertRemoveLength = Object.keys(this.stagedCommentsRevert.$remove).length

          let objects: DiagramContentPartial['objects'] | undefined
          if (objectsAddLength) {
            objects = objects || {}
            objects.$add = this.stagedObjects.$add
          }
          if (objectsUpdateLength) {
            objects = objects || {}
            objects.$update = this.stagedObjects.$update
          }
          if (objectsRemoveLength) {
            objects = objects || {}
            objects.$remove = Object.keys(this.stagedObjects.$remove)
          }

          let objectsRevert: DiagramContentPartial['objects'] | undefined
          if (objectsRevertAddLength) {
            objectsRevert = objectsRevert || {}
            objectsRevert.$add = this.stagedObjectsRevert.$add
          }
          if (objectsRevertUpdateLength) {
            objectsRevert = objectsRevert || {}
            objectsRevert.$update = this.stagedObjectsRevert.$update
          }
          if (objectsRevertRemoveLength) {
            objectsRevert = objectsRevert || {}
            objectsRevert.$remove = Object.keys(this.stagedObjectsRevert.$remove)
          }

          let connections: DiagramContentPartial['connections'] | undefined
          if (connectionsAddLength) {
            connections = connections || {}
            connections.$add = this.stagedConnections.$add
          }
          if (connectionsUpdateLength) {
            connections = connections || {}
            connections.$update = this.stagedConnections.$update
          }
          if (connectionsRemoveLength) {
            connections = connections || {}
            connections.$remove = Object.keys(this.stagedConnections.$remove)
          }

          let connectionsRevert: DiagramContentPartial['connections'] | undefined
          if (connectionsRevertAddLength) {
            connectionsRevert = connectionsRevert || {}
            connectionsRevert.$add = this.stagedConnectionsRevert.$add
          }
          if (connectionsRevertUpdateLength) {
            connectionsRevert = connectionsRevert || {}
            connectionsRevert.$update = this.stagedConnectionsRevert.$update
          }
          if (connectionsRevertRemoveLength) {
            connectionsRevert = connectionsRevert || {}
            connectionsRevert.$remove = Object.keys(this.stagedConnectionsRevert.$remove)
          }

          let comments: DiagramContentPartial['comments'] | undefined
          if (commentsAddLength) {
            comments = comments || {}
            comments.$add = this.stagedComments.$add
          }
          if (commentsUpdateLength) {
            comments = comments || {}
            comments.$update = this.stagedComments.$update
          }
          if (commentsRemoveLength) {
            comments = comments || {}
            comments.$remove = Object.keys(this.stagedComments.$remove)
          }

          let commentsRevert: DiagramContentPartial['comments'] | undefined
          if (commentsRevertAddLength) {
            commentsRevert = commentsRevert || {}
            commentsRevert.$add = this.stagedCommentsRevert.$add
          }
          if (commentsRevertUpdateLength) {
            commentsRevert = commentsRevert || {}
            commentsRevert.$update = this.stagedCommentsRevert.$update
          }
          if (commentsRevertRemoveLength) {
            commentsRevert = commentsRevert || {}
            commentsRevert.$remove = Object.keys(this.stagedCommentsRevert.$remove)
          }

          if (this.currentDiagram.status === 'draft') {
            const diagramContentUpdateRevert: DiagramContentPartial = {
              comments: commentsRevert,
              connections: connectionsRevert,
              objects: objectsRevert
            }

            const { diagramContent, diagramContentUpdate, modelConnectionDiagramAdd, modelConnectionDiagramRemove } = this.diagramModule.generateDiagramContentCommit(this.currentDiagramContent.id, {
              comments,
              connections,
              objects
            })
            this.diagramModule.setDiagramContentVersion(diagramContent)
            this.modelModule.setConnectionDiagrams({ modelConnectionDiagramAdd, modelConnectionDiagramRemove })
            this.editorModule.addToTaskQueue({
              func: () => this.diagramModule.diagramContentUpdate({
                diagramId: diagramContent.id,
                landscapeId: this.currentLandscape.id,
                props: diagramContentUpdate,
                versionId: this.currentVersion.id
              })
            })

            this.stagedObjects = {
              $add: {},
              $remove: {},
              $update: {}
            }
            this.stagedObjectsRevert = {
              $add: {},
              $remove: {},
              $update: {}
            }
            this.stagedConnections = {
              $add: {},
              $remove: {},
              $update: {}
            }
            this.stagedConnectionsRevert = {
              $add: {},
              $remove: {},
              $update: {}
            }
            this.stagedComments = {
              $add: {},
              $remove: {},
              $update: {}
            }
            this.stagedCommentsRevert = {
              $add: {},
              $remove: {},
              $update: {}
            }

            return {
              diagramContentUpdate,
              diagramContentUpdateRevert
            }
          } else {
            const diagramContentUpdateRevert: DiagramContentPartial = {
              comments: commentsRevert,
              connections: connectionsRevert,
              objects: objectsRevert
            }

            const { diagramContent, diagramContentUpdate, modelConnectionDiagramAdd, modelConnectionDiagramRemove } = this.diagramModule.generateDiagramContentCommit(this.currentDiagramContent.id, {
              comments,
              connections,
              objects
            })
            this.diagramModule.setDiagramContentVersion(diagramContent)
            this.modelModule.setConnectionDiagrams({ modelConnectionDiagramAdd, modelConnectionDiagramRemove })
            this.editorModule.addToTaskQueue({
              func: () => this.diagramModule.diagramContentUpdate({
                diagramId: diagramContent.id,
                landscapeId: this.currentLandscape.id,
                props: diagramContentUpdate,
                versionId: this.currentVersion.id
              })
            })

            this.stagedObjects = {
              $add: {},
              $remove: {},
              $update: {}
            }
            this.stagedObjectsRevert = {
              $add: {},
              $remove: {},
              $update: {}
            }
            this.stagedConnections = {
              $add: {},
              $remove: {},
              $update: {}
            }
            this.stagedConnectionsRevert = {
              $add: {},
              $remove: {},
              $update: {}
            }
            this.stagedComments = {
              $add: {},
              $remove: {},
              $update: {}
            }
            this.stagedCommentsRevert = {
              $add: {},
              $remove: {},
              $update: {}
            }

            return {
              diagramContentUpdate,
              diagramContentUpdateRevert
            }
          }
        }
      })
      this.view.on('reassign-connection', async (id, originId, originConnector, targetId, targetConnector) => {
        await this.$nextTick()
        await this.$replaceQuery({
          connection_reassign_dialog: `${id}_${originId || ''}_${originConnector || ''}_${targetId || ''}_${targetConnector || ''}`
        })
      })
      this.view.on('objects-delete', objectIds => {
        this.$emit('objects-delete', objectIds)
      })
      this.view.on('object-add', type => {
        analytics.diagramObjectAdd.track(this, {
          diagramType: this.currentDiagramContent.type,
          landscapeId: [this.currentLandscape.id],
          modelObjectType: type,
          organizationId: [this.currentLandscape.organizationId]
        })
      })
      this.view.on('object-create', type => {
        analytics.diagramObjectCreate.track(this, {
          diagramType: this.currentDiagramContent.type,
          landscapeId: [this.currentLandscape.id],
          modelObjectType: type,
          organizationId: [this.currentLandscape.organizationId]
        })
      })
      this.view.on('open-flow-step', id => {
        const flowStep = this.currentFlow?.steps[id]
        if (this.currentFlow && flowStep) {
          if (flowStep.pathId) {
            this.$replaceQuery({
              flow_path: {
                ...this.$unionQueryArray(flowStep.pathId),
                ...this.$removeQueryArray(...Object.values(this.currentFlow.steps).filter(o => o.type?.endsWith('path') && o.index === flowStep.index && o.id !== flowStep.pathId).map(s => s.id))
              },
              flow_step: flowStep.id,
              object: undefined
            })
          } else {
            this.$replaceQuery({
              flow_step: flowStep.id,
              object: undefined
            })
          }
        }
      })
      this.view.on('zoom', async (type, modelId) => {
        let diagramId: string | undefined

        const modelObject = this.modelModule.objects[modelId]
        if (type === 'out' && modelObject) {
          const lastRouteForModel = this.routeModule.history.find(o => o.query?.model === modelObject.handleId)
          const lastRouteDiagram = Object.values(this.diagramModule.diagrams).find(o => o.modelId === modelId && o.handleId === lastRouteForModel?.query?.diagram)
          diagramId = lastRouteDiagram?.id
        }

        const explore = await this.diagramModule.diagramsExplore({
          diagramId,
          landscapeId: this.currentLandscapeId,
          modelId,
          versionId: this.currentVersionId
        })
        if (explore) {
          this.zoomUserGoalRef?.complete()

          this.diagramModule.diagramAction({
            action: 'zoom',
            diagramId: explore.diagram.id,
            landscapeId: this.currentLandscapeId,
            versionId: this.currentVersionId
          })

          await this.$pushQuery({
            diagram: explore.diagram.handleId,
            flow: undefined,
            flow_parent: undefined,
            flow_path: undefined,
            flow_step: undefined,
            model: explore.modelObject.handleId,
            object: undefined,
            x1: undefined,
            x2: undefined,
            y1: undefined,
            y2: undefined
          })

          analytics.diagramObjectZoom.track(this, {
            diagramType: explore.diagram.type,
            landscapeId: [this.currentLandscape.id],
            modelObjectType: explore.modelObject.type,
            organizationId: [this.currentLandscape.organizationId]
          })
        }
      })
      this.view.on('open-connection', (modelId) => {
        const modelConnection = this.modelModule.connections[modelId]

        const directConnection = Object.values(modelConnection.diagrams).find(o => o.originModelId === modelConnection.originId && o.targetModelId === modelConnection.targetId)
        const directConnectionDiagram = directConnection ? this.diagramModule.diagrams[directConnection.id] : undefined
        const directConnectionDiagramModel = directConnectionDiagram ? this.modelModule.objects[directConnectionDiagram.modelId] : undefined

        if (this.$routeName && directConnection && directConnectionDiagram && directConnectionDiagramModel) {
          this.$router.push({
            name: this.$routeName,
            query: this.$setQuery({
              connection: directConnection.connectionId,
              diagram: directConnectionDiagram.handleId,
              flow: undefined,
              flow_parent: undefined,
              flow_path: undefined,
              flow_step: undefined,
              model: directConnectionDiagramModel.handleId,
              object: undefined,
              x1: undefined,
              x2: undefined,
              y1: undefined,
              y2: undefined
            })
          })
        } else {
          this.alertModule.pushAlert({
            color: 'grey darken-1',
            message: `No direct connections are drawn for ${modelConnection.name}`
          })
        }
      })
      this.view.on('open-link', objectId => {
        const modelId = this.currentDiagramContent.objects[objectId]?.modelId

        const links = Object.values(this.modelModule.objects[modelId]?.links || {})
        if (links.length === 1 && (links[0].type === 'url' || links[0].status === 'valid')) {
          openExternalLink(links[0].url)

          this.view?.clearAllHover()
          this.view?.resetAllConnectors()

          modelAnalytics.modelObjectLinkOpen.track(this, {
            landscapeId: [this.currentLandscape.id],
            modelObjectLinkType: links[0].type,
            organizationId: [this.currentLandscape.organizationId]
          })
        } else {
          this.$replaceQuery({
            object: [objectId]
          })
        }
      })
      this.view.on('select', async ({ objectIds, commentIds, connectionIds }) => {
        const query: any = {
          expanded_connection: undefined,
          expanded_connection_tab: undefined,
          tag_picker_menu: undefined,
          team_picker_menu: undefined
        }
        if (objectIds) {
          query.object = objectIds
        }
        if (connectionIds) {
          query.connection = connectionIds
        }
        if (commentIds && !this.currentCommentIds.some(o => o.startsWith('new'))) {
          query.comment = commentIds
        }

        await this.$replaceQuery(query)
        await this.$nextTick()

        this.findCommentThrottle()
      })
      this.view.on('select-tool', tool => {
        this.editorModule.setToolbarSelection(tool)
      })
      this.view.on('alert', alert => {
        this.alertModule.pushAlert(alert)
      })
      this.view.on('state', (state, prevState) => {
        this.findPrimaryObjectsThrottle()
        this.findCommentThrottle()

        if (state.name === 'create-object-existing') {
          ObjectPool.addObject(state.object)
        }
        if (prevState.name === 'create-object-existing') {
          ObjectPool.removeObject(prevState.object)
        }
      })
      this.view.on('object-create-menu', menu => {
        if (menu) {
          this.creatingObjectPosition = {
            x: menu.x + this.left,
            y: menu.y + 56
          }
          this.creatingObjectPositionOriginModelId = menu.originModelId
        } else {
          this.creatingObjectPosition = null
        }
      })
      this.view.on('connection-create-menu', menu => {
        if (menu) {
          const x = menu.x + this.left - (240 / 2) + 4
          this.creatingConnectionPosition = {
            x: Math.floor(x / 10) * 10,
            y: menu.y + 56 + 2
          }
          this.creatingConnectionPositionOriginModelId = menu.originModelId
          this.creatingConnectionPositionTargetModelId = menu.targetModelId
        } else {
          this.creatingConnectionPosition = null
        }
      })
      this.view.on('object-create-filter', name => {
        this.editorModule.setObjectCreateFilterName(name)
      })
      this.view.on('connection-create-filter', name => {
        this.editorModule.setConnectionCreateFilterName(name)
      })
      this.view.on('open-active-users-list', async () => {
        this.$replaceQuery({
          users_menu: '1'
        })
      })

      this.canvasRef.onselectstart = () => false

      // tabbing will create a div that is more than the height of the page
      this.app.renderer.plugins.accessibility.destroy()

      if (window.Cypress) {
        window.view = this.view
      }

      Ticker.shared.start()

      this.canvasRef.appendChild(this.app.view)
      this.resize()
      this.app.view.focus({ preventScroll: true })

      this.blurListener = this.blur.bind(this)
      document.addEventListener('blur', this.blurListener, true)

      this.restoreCameraPosition()

      this.scale = this.view.scene.scale.x
      this.$emit('scale', this.scale)

      Object.values(this.objects).forEach(o => this.upsertObject(o))
      Object.values(this.connections).forEach(o => this.upsertConnection(o))
      Object.values(this.comments).forEach(o => this.upsertComment(o))

      Object.values(this.objects).forEach(o => this.initObject(o.object.id))
      Object.values(this.connections).forEach(o => this.initConnection(o.connection.id))
      Object.values(this.comments).forEach(o => this.initComment(o.diagramComment.id))

      this.view.flow.setModel(this.currentFlowPathSteps || null)
      this.view.flow.setStore(this.currentFlowSteps)

      Object.values(this.view.objects).forEach(o => o.setSelected(this.currentObjectIds.includes(o.id)))
      Object.values(this.view.connections).forEach(o => o.setSelected(this.currentConnectionIds.includes(o.id)))
      Object.values(this.view.comments).forEach(o => o.setSelected(this.currentCommentIds.includes(o.id)))

      this.findPrimaryObjectsThrottle()
      this.findCommentThrottle()

      this.view.highlight = this.highlight[0] || null

      await this.$nextTick()

      this.view.on('pan', () => {
        if (this.view) {
          const sceneRect = this.view.sceneRect
          const x1 = sceneRect.x - this.left
          const y1 = sceneRect.y
          const x2 = sceneRect.x + sceneRect.width + this.right
          const y2 = sceneRect.y + sceneRect.height

          this.updateQueryDebounce({ x1, x2, y1, y2 })
        }

        this.findPrimaryObjectsThrottle()
        this.findCommentThrottle()
      })
      this.view.on('scale', scale => {
        this.scale = scale
        this.$emit('scale', scale)

        if (this.view) {
          const sceneRect = this.view.sceneRect
          const x1 = sceneRect.x - this.left
          const y1 = sceneRect.y
          const x2 = sceneRect.x + sceneRect.width + this.right
          const y2 = sceneRect.y + sceneRect.height

          this.updateQueryDebounce({ x1, x2, y1, y2 })
        }

        this.findPrimaryObjectsThrottle()
        this.findCommentThrottle()
      })

      this.$emit('loaded')
    } catch (err: any) {
      this.$emit('error', err.message)
      throw err
    }
  }

  beforeDestroy () {
    if (this.app) {
      this.canvasRef.removeChild(this.app.view)
    }

    this.updateQueryDebounce.cancel()
  }

  destroyed () {
    clearTimeout(this.blurTimer)

    Ticker.shared.stop()

    this.updateQueryDebounce.cancel()

    if (this.blurListener) {
      document.removeEventListener('blur', this.blurListener, true)
    }

    Object.keys(this.view?.comments || {}).forEach(o => this.removeComment(o))
    Object.keys(this.view?.connections || {}).forEach(o => this.removeConnection(o))
    Object.keys(this.view?.objects || {}).forEach(o => this.removeObject(o))

    this.view?.destroy()
  }

  resize () {
    if (this.app && this.canvasRef.childElementCount) {
      this.app.renderer.resize(this.containerRef.clientWidth - this.right - this.left, this.containerRef.clientHeight)
      this.app.render()
    }

    if (this.view) {
      const sceneRect = this.view.sceneRect
      const x1 = sceneRect.x - this.left
      const y1 = sceneRect.y
      const x2 = sceneRect.x + sceneRect.width + this.right
      const y2 = sceneRect.y + sceneRect.height

      this.updateQueryDebounce({ x1, x2, y1, y2 })
    }
  }

  resetCameraPosition () {
    const currentFlowStepsViaIds = this.currentFlowSteps.map(o => o.viaId)
    const currentFlowStepsOriginIds = this.currentFlowSteps.map(o => o.originId)
    const currentFlowStepsTargetIds = this.currentFlowSteps.map(o => o.targetId)

    if (currentFlowStepsViaIds.length || currentFlowStepsOriginIds.length || currentFlowStepsTargetIds.length) {
      this.view?.transitionState(new Context.Diagram.State.Teleport({
        connections: Object.values(this.connections).filter(o => currentFlowStepsViaIds.includes(o.connection.id)).map(o => o.connection),
        objects: Object.values(this.objects).filter(o => currentFlowStepsOriginIds.includes(o.object.id) || currentFlowStepsTargetIds.includes(o.object.id)).map(o => o.object)
      }))
    } else if (this.currentObjectIds.length || this.currentConnectionIds.length || this.currentCommentIds.length) {
      const connections = [
        ...Object
          .values(this.connections)
          .filter(o => this.currentConnectionIds.includes(o.connection.id))
          .map(o => o.connection),
        ...Object
          .values(this.connections)
          .filter(o => {
            if (!o.model || o.model?.direction === 'outgoing') {
              return o.connection.originId && this.currentObjectIds.includes(o.connection.originId)
            } else {
              return (o.connection.originId && this.currentObjectIds.includes(o.connection.originId)) || (o.connection.targetId && this.currentObjectIds.includes(o.connection.targetId))
            }
          })
          .map(o => o.connection)
      ]
      const connectionObjectIds = connections.map(o => [o.originId, o.targetId]).flat().filter((o): o is string => !!o)
      const objects = Object
        .values(this.objects)
        .filter(o => this.currentObjectIds.includes(o.object.id) || connectionObjectIds.includes(o.object.id))
        .map(o => o.object)
      const comments = Object
        .values(this.comments)
        .filter(o => this.currentCommentIds.includes(o.diagramComment.id))
        .map(o => o.diagramComment)
      this.view?.transitionState(new Context.Diagram.State.Teleport({
        comments,
        connections,
        objects
      }))
    } else {
      this.view?.transitionState(new Context.Diagram.State.Teleport({
        comments: Object.values(this.comments).map(o => o.diagramComment),
        connections: Object.values(this.connections).map(o => o.connection),
        objects: Object.values(this.objects).map(o => o.object)
      }))
    }

    analytics.diagramResetCameraPosition.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  restoreCameraPosition () {
    const queryX1 = typeof this.$query.x1 === 'string' ? parseFloat(this.$query.x1 || '') : undefined
    const queryY1 = typeof this.$query.y1 === 'string' ? parseFloat(this.$query.y1 || '') : undefined
    const queryX2 = typeof this.$query.x2 === 'string' ? parseFloat(this.$query.x2 || '') : undefined
    const queryY2 = typeof this.$query.y2 === 'string' ? parseFloat(this.$query.y2 || '') : undefined

    const currentFlowStepsViaIds = this.currentFlowSteps.map(o => o.viaId)
    const currentFlowStepsOriginIds = this.currentFlowSteps.map(o => o.originId)
    const currentFlowStepsTargetIds = this.currentFlowSteps.map(o => o.targetId)

    if (this.view) {
      if (queryX1 && queryY1 && queryX2 && queryY2) {
        this.view.transitionState(new Context.Diagram.State.Teleport({
          x1: queryX1 + this.left,
          x2: queryX2 - this.right,
          y1: queryY1,
          y2: queryY2
        }, {
          animate: false
        }))
      } else if (this.view && this.currentComment && !pointInRect(this.currentComment.diagramComment, this.view.sceneRect)) {
        this.view.transitionState(new Context.Diagram.State.Teleport({
          comments: [this.currentComment.diagramComment]
        }, {
          animate: false
        }))
      } else if (currentFlowStepsViaIds.length || currentFlowStepsOriginIds.length || currentFlowStepsTargetIds.length) {
        this.view.transitionState(new Context.Diagram.State.Teleport({
          connections: Object.values(this.connections).filter(o => currentFlowStepsViaIds.includes(o.connection.id)).map(o => o.connection),
          objects: Object.values(this.objects).filter(o => currentFlowStepsOriginIds.includes(o.object.id) || currentFlowStepsTargetIds.includes(o.object.id)).map(o => o.object)
        }, {
          animate: false
        }))
      } else if (this.currentCommentIds.length || this.currentObjectIds.length || this.currentConnectionIds.length) {
        const connections = [
          ...Object
            .values(this.connections)
            .filter(o => this.currentConnectionIds.includes(o.connection.id))
            .map(o => o.connection),
          ...Object
            .values(this.connections)
            .filter(o => {
              if (!o.model || o.model?.direction === 'outgoing') {
                return o.connection.originId && this.currentObjectIds.includes(o.connection.originId)
              } else {
                return (o.connection.originId && this.currentObjectIds.includes(o.connection.originId)) || (o.connection.targetId && this.currentObjectIds.includes(o.connection.targetId))
              }
            })
            .map(o => o.connection)
        ]
        const connectionObjectIds = connections.map(o => [o.originId, o.targetId]).flat().filter((o): o is string => !!o)
        const objects = Object
          .values(this.objects)
          .filter(o => this.currentObjectIds.includes(o.object.id) || connectionObjectIds.includes(o.object.id))
          .map(o => o.object)
        const comments = Object
          .values(this.comments)
          .filter(o => this.currentCommentIds.includes(o.diagramComment.id))
          .map(o => o.diagramComment)
        this.view.transitionState(new Context.Diagram.State.Teleport({
          comments,
          connections,
          objects
        }, {
          animate: false
        }))
      } else {
        this.view.transitionState(new Context.Diagram.State.Teleport({
          comments: Object.values(this.comments).map(o => o.diagramComment),
          connections: Object.values(this.connections).map(o => o.connection),
          objects: Object.values(this.objects).map(o => o.object)
        }, {
          animate: false
        }))
      }
    }
  }

  updateQueryDebounce = debounce(this.updateQuery.bind(this), 600, {
    trailing: true
  })

  async updateQuery ({ x1, y1, x2, y2 }: { x1?: number, y1?: number, x2?: number, y2?: number }) {
    const query: any = {}
    if (x1 !== undefined) {
      query.x1 = Math.round(x1 * 10) / 10
    }
    if (y1 !== undefined) {
      query.y1 = Math.round(y1 * 10) / 10
    }
    if (x2 !== undefined) {
      query.x2 = Math.round(x2 * 10) / 10
    }
    if (y2 !== undefined) {
      query.y2 = Math.round(y2 * 10) / 10
    }
    await this.$replaceQuery(query)
  }

  blur () {
    clearTimeout(this.blurTimer)
    this.blurTimer = window.setTimeout(() => {
      if (document.activeElement === document.body && window === window.parent) {
        this.view?.app?.view?.focus({ preventScroll: true })
      }
    }, 100)
  }

  zoomButton (scale: number) {
    if (this.view) {
      const curScale = (this.view.state.name === 'teleport' && 'scale' in this.view.state.target ? this.view.state.target.scale : undefined) || this.view.scene.scale.x
      const newScale = Math.max(0.1, Math.min(2, curScale + scale))
      this.view.transitionState(new Context.Diagram.State.Teleport({
        scale: newScale
      }, {
        ease: this.view.state.name === 'teleport' ? 'out' : undefined
      }))
    }
  }

  findPrimaryObjectsThrottle = throttle(this.findPrimaryObjects.bind(this), 20, {
    trailing: true
  })

  findPrimaryObjects () {
    this.primaryObject = null

    const view = this.view
    if (view) {
      view.highlightAddObjectId = null

      const zoomUserGoalCompleted = !!this.zoomUserGoalRef && this.zoomUserGoalRef.active && !!this.zoomUserGoalRef.completed

      if (!zoomUserGoalCompleted && (view.state.name === 'main' || view.state.name === 'background' || view.state.name === 'pan' || view.state.name === 'read-only')) {
        const primaryObject = Object
          .values(this.objects)
          .filter(o => o.object.shape === 'box' && (o.object.type === 'system' || o.object.type === 'app' || o.object.type === 'store' || o.object.type === 'component'))
          .reduce<{ object: DiagramObject, model: ModelObject } | null>((p, c) => {
            if (p) {
              if (c.model.childIds.length === p.model.childIds.length) {
                return c.model.name.localeCompare(p.model.name) > 0 ? c : p
              } else if (c.model.childIds.length > p.model.childIds.length) {
                return c
              } else {
                return p
              }
            } else {
              return c
            }
          }, null)

        if (primaryObject && rectIntersects(primaryObject.object, view.sceneRect)) {
          const { x, y } = view.scene.toGlobal({
            x: primaryObject.object.x + 17,
            y: primaryObject.object.y + 17
          })

          this.primaryObject = {
            x: x + (this.currentFlow && this.drawer === 'expanded' ? 320 : 0),
            y
          }

          view.highlightAddObjectId = primaryObject.object.id
        }

        this.zoomUserGoalRef?.updateDimensions()
      }
    }
  }

  findCommentThrottle = throttle(this.findComment.bind(this), 20, {
    trailing: true
  })

  findComment () {
    this.commentDisabled = this.view?.state.name !== 'main' && this.view?.state.name !== 'pan' && this.view?.state.name !== 'background' && this.view?.state.name !== 'teleport' && this.view?.state.name !== 'read-only'

    const comment = this.currentComment ? this.view?.comments[this.currentComment.diagramComment.id] : undefined
    if (comment && this.view) {
      const sceneRect = this.view.sceneRect
      const { x, y } = this.view.scene.toGlobal({
        x: Math.max(Math.min(sceneRect.x + sceneRect.width, comment.x + (comment.circleRadius * 2)), sceneRect.x) + 4,
        y: Math.max(Math.min(sceneRect.y + sceneRect.height, comment.y), sceneRect.y) - 4
      })

      this.commentPosition = snapToGrid({ x: comment.x, y: comment.y }, 1)
      this.commentPositionGlobal = snapToGrid({ x, y: y + 40 }, 1)

      this.$nextTick(() => this.commentMenu?.updateDimensions())
    } else {
      this.commentPosition = null
    }
  }

  upsertComment (comment: DiagramCanvas['comments'][0]) {
    if (this.view) {
      const com = this.view.comments[comment.diagramComment.id]
      if (com) {
        com.setModel(comment.comment, comment.comment.id === 'new')
        com.setStore(comment.diagramComment)
        com.setSelected(this.currentCommentIds.includes(comment.diagramComment.id))
        this.view.updateCulling([com])
        this.findCommentThrottle()
        return com
      } else {
        const com = ObjectPool.reuseObject(Context.Diagram.Obj.Comment, comment.diagramComment.id) || new Context.Diagram.Obj.Comment(this.view, comment.diagramComment.id)
        com.setView(this.view)
        com.setModel(comment.comment, true)
        com.setStore(comment.diagramComment)
        com.setSelected(this.currentCommentIds.includes(comment.diagramComment.id))
        this.view.comments[com.id] = com
        this.view.updateCulling([com])
        this.findCommentThrottle()
        ObjectPool.addObject(com)
        return com
      }
    } else {
      throw new Error('Could not find view')
    }
  }

  initComment (commentId: string) {
    if (this.view) {
      const com = this.view.comments[commentId]
      if (com.initialized) {
        com.resetModel()
      } else {
        com.init()
      }
      this.view.scene.addChild(com as DisplayObject)
      this.view.updateCulling([com])
      return com
    } else {
      throw new Error('Could not find view')
    }
  }

  removeComment (commentId: string) {
    if (this.view) {
      const com = this.view.comments[commentId]
      if (com) {
        this.view.scene.removeChild(com as DisplayObject)
        delete this.view.comments[commentId]
        ObjectPool.recycleObject(com)
      }
    }
  }

  upsertObject ({ object, model, colors, locked, pinned, pinnedKey, pinnedIcon, inScope, domain, activeUserIds }: DiagramCanvas['objects'][0], prev?: DiagramCanvas['objects'][0]) {
    if (this.view) {
      let obj = this.view.objects[object.id]
      if (obj) {
        const ignoreVersion = model.external !== prev?.model.external || model.status !== prev?.model.status
        obj.setModel(model, ignoreVersion)
        if (!isEqual(object, prev?.object)) {
          obj.setStore(object)
        }
        if ('setColors' in obj && !isEqual(colors, prev?.colors)) {
          obj.setColors(colors)
        }
        if ('setPinned' in obj && (pinnedKey !== prev?.pinnedKey || pinnedIcon !== prev?.pinnedIcon || !isEqual(colors, prev?.colors))) {
          obj.setPinned(pinned, pinnedIcon)
        }
        if ('setStyle' in obj) {
          obj.setStyle({
            domain: domain?.name,
            inScope
          })
        }
        if ('setActiveUserIds' in obj && !isEqual(activeUserIds, prev?.activeUserIds)) {
          obj.setActiveUserIds(activeUserIds)
        }
        if ('setLocked' in obj && locked !== prev?.locked) {
          obj.setLocked(locked)
        }
        obj.setSelected(this.currentObjectIds.includes(object.id))
        this.view.updateCulling([obj])
        return obj
      } else {
        if (object.shape === 'area') {
          switch (object.type) {
            case 'group': {
              obj = ObjectPool.reuseObject(Context.Diagram.Obj.Group, object.id) || new Context.Diagram.Obj.Group(this.view, object.id)
              break
            }
            default: {
              obj = ObjectPool.reuseObject(Context.Diagram.Obj.Area, object.id) || new Context.Diagram.Obj.Area(this.view, object.id)
              break
            }
          }
        } else {
          switch (object.type) {
            case 'actor': {
              obj = ObjectPool.reuseObject(Context.Diagram.Obj.Actor, object.id) || new Context.Diagram.Obj.Actor(this.view, object.id)
              break
            }
            case 'app': {
              obj = ObjectPool.reuseObject(Context.Diagram.Obj.App, object.id) || new Context.Diagram.Obj.App(this.view, object.id)
              break
            }
            case 'component': {
              obj = ObjectPool.reuseObject(Context.Diagram.Obj.Comp, object.id) || new Context.Diagram.Obj.Comp(this.view, object.id)
              break
            }
            case 'system': {
              obj = ObjectPool.reuseObject(Context.Diagram.Obj.System, object.id) || new Context.Diagram.Obj.System(this.view, object.id)
              break
            }
            case 'store': {
              obj = ObjectPool.reuseObject(Context.Diagram.Obj.Store, object.id) || new Context.Diagram.Obj.Store(this.view, object.id)
              break
            }
            default: {
              throw new Error(`Failed to create ${object.type} object ${object.shape} shape`)
            }
          }
        }
        obj.setView(this.view)
        obj.setModel(model, true)
        obj.setStore(object)
        if ('setColors' in obj) {
          obj.setColors(colors)
        }
        if ('setPinned' in obj) {
          obj.setPinned(pinned, pinnedIcon)
        }
        if ('setStyle' in obj) {
          obj.setStyle({
            domain: domain?.name,
            inScope
          })
        }
        if ('setActiveUserIds' in obj) {
          obj.setActiveUserIds(activeUserIds)
        }
        if ('setLocked' in obj && locked !== prev?.locked) {
          obj.setLocked(locked)
        }
        obj.setSelected(this.currentObjectIds.includes(object.id))
        this.view.objects[obj.id] = obj
        this.view.updateCulling([obj])
        ObjectPool.addObject(obj)
      }
      return obj
    } else {
      throw new Error('Could not find view')
    }
  }

  initObject (objectId: string) {
    if (this.view) {
      const obj = this.view.objects[objectId]
      if (obj.initialized) {
        obj.resetModel()
        obj.resetStore()
      } else {
        obj.init()
      }
      this.view.scene.addChild(obj as DisplayObject)
      this.view.updateCulling([obj])
      return obj
    } else {
      throw new Error('Could not find view')
    }
  }

  removeObject (objectId: string) {
    if (this.view) {
      const obj = this.view.objects[objectId]
      if (obj) {
        this.view.highlightObjectIds = this.view.highlightObjectIds.filter(o => o !== objectId)
        this.view.scene.removeChild(obj as DisplayObject)
        delete this.view.objects[objectId]
        ObjectPool.recycleObject(obj)
      }
    }
  }

  upsertConnection ({ connection, locked, model, pinned, pinnedKey, color, overlay, overlayKey, lower }: DiagramCanvas['connections'][0], prev?: DiagramCanvas['connections'][0]) {
    if (this.view) {
      let con = this.view.connections[connection.id]
      if (con) {
        const ignoreVersion = model?.status !== prev?.model?.status
        con.setModel(model, ignoreVersion)
        if (!isEqual(connection, prev?.connection)) {
          con.setStore(connection)
        }
        if (overlayKey !== prev?.overlayKey || color !== prev?.color || pinnedKey !== prev?.pinnedKey) {
          con.setOverlay(overlay, color, pinned)
        }
        if (locked !== prev?.locked) {
          con.setLocked(locked)
        }
        con.setStyle({ lower })
        con.setSelected(this.currentConnectionIds.includes(connection.id))
        this.view.updateCulling([con])
      } else {
        con = ObjectPool.reuseObject(Context.Diagram.Obj.Connection, connection.id) || new Context.Diagram.Obj.Connection(this.view, connection.id)
        con.setView(this.view)
        con.setModel(model, true)
        con.setStore(connection)
        con.setOverlay(overlay, color, pinned)
        con.setLocked(locked)
        con.setStyle({ lower })
        con.setSelected(this.currentConnectionIds.includes(connection.id))
        this.view.connections[con.id] = con
        this.view.updateCulling([con])
        ObjectPool.addObject(con)
      }
      return con
    } else {
      throw new Error('Could not find view')
    }
  }

  initConnection (connectionId: string) {
    if (this.view) {
      const con = this.view.connections[connectionId]
      if (con.initialized) {
        con.resetModel()
        con.resetStore()
      } else {
        con.init()
      }
      this.view.scene.addChild(con as DisplayObject)
      this.view.updateCulling([con])
      return con
    } else {
      throw new Error('Could not find view')
    }
  }

  removeConnection (connectionId: string) {
    if (this.view) {
      const obj = this.view.connections[connectionId]
      if (obj) {
        this.view.scene.removeChild(obj as DisplayObject)
        delete this.view.connections[connectionId]
        ObjectPool.recycleObject(obj)
      }
    }
  }

  userGoalVideoZoom () {
    userAnalytics.userGoalVideoOpen.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      userGoalName: 'zoom-into-object'
    })
  }

  createObject (type?: ModelObjectType, modelId?: string) {
    if (this.view?.state.name === 'create-object') {
      const created = this.view.state.create(type, modelId)
      if (created) {
        if (this.view.state.connection) {
          this.view.transitionState(new Context.Diagram.State.CreateConnection(this.view.state.connection))
        } else {
          this.view.transitionState(new Context.Diagram.State.Main())
        }
      }
    }
  }

  createConnection (modelId?: string, reassignConnection = false) {
    if (this.view?.state.name === 'create-connection') {
      this.view.state.create(modelId, reassignConnection)
      this.view.transitionState(new Context.Diagram.State.Main())
    }
  }

  deleteConnection () {
    if (this.view?.state.name === 'create-connection') {
      this.view.transitionState(new Context.Diagram.State.Main())
    }
  }

  isModelObjectProtected (modelObjectId: string) {
    if (this.permission === 'admin') { return false }
    if (!modelObjectId) { return false }
    const modelObject = this.modelModule.objects[modelObjectId]
    return (
      modelObject &&
      modelObject.teamOnlyEditing &&
      !!modelObject.teamIds.length &&
      !this.teamModule.userTeams.some(o => modelObject.teamIds.includes(o.id))
    )
  }
}
